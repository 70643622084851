/* body{background-color: #ccc;} */
/* FONT AVERSOME ICONS */
.person {display:block;text-align:center;}
.big {font-size:3em} 
.gray{color:#555;}
/* GLOBAL */
a{color:#555;}
.navLink{color:#444;}
/* KOLORY SYSTEMOWE */
.red{
  color:red;
}
/* WALIDATORY */
.validation{height: 20px;display:block}
/* LOADER */
.loader{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: 
  translate(-50%, -50%);
  margin:auto
}
/* LOGIN FROM */
.loginform{
  background-color: rgb(126, 150, 255);
  border: 1px solid rgb(100, 65, 253);
  padding:10px;
  width:30%;
  position:relative;
  z-index:2000;
  min-width: 300px;
  margin:auto;
  margin-top:10%;
  -webkit-box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.75);

}
.loginform .header{text-align: center;}

.loginform .header h3{border-bottom: 1px solid #777;padding:5px}
.remindPassword{cursor: pointer;}
/* MAINBOARD */
.toppanel{background-color:rgb(109, 155, 241);padding:10px;font-size: 0.7em;position:relative;}
.logout{cursor:pointer;}
.setka{width:100%}

label{font-weight:300;color:#333;margin-bottom:1px;font-size:0.8em;}
label::before{content:"✎";}
.AddInfo{margin-bottom:5px;}
.myInput
{
  width:100%;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: 1px solid rgba(255,255,255,0);
  border-bottom-color: #a1a1a1;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: rgba(38,45,48,1);
  -o-text-overflow: clip;
  text-overflow: clip;
  background: rgba(255,255,255,0.33);
  -webkit-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  -moz-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
}
.myInput.disabled{background-color: #ccc;}
.myInput.incorrect
{
  background-color: rgb(255, 148, 148);
}

.myInput:disabled {
  background: #ddd;
}

.myInfo{
  padding:5px;
  background-color: rgb(88, 88, 156);
  color:rgb(232, 226, 255);
  font-size: 0.8em;
}
.clickable{cursor: pointer;}
/* .myInput.search::after{
  content:"⌕IIIOIOO";
  color:black;
  z-index:1000;
  position:absolute;
  font-size: 2em;
} */
.divAsTextArea{
  border:1px solid #ddd;
  width:100%;
  min-height:100px;
  padding:5px;
  font-size:0.7em;
}
.haveInfo{
  background-color: rgb(184, 184, 184);
  border-left:5px solid rgb(32, 175, 32);
  color:white;
  margin-bottom:5px;
  padding-left:10px;
}

.asRemoveButton:after{
  display: inline-block;
  content: "\00d7";
  cursor: pointer;
  background-color:#333;
  width:24px;
  padding-left:6px;
  -webkit-transition: color 1s;
  transition: color 1s
  
}
.asAddButton:after{
  display: inline-block;
  content: "+";
  cursor: pointer;
  background-color:rgb(30, 155, 51);
  width:24px;
  padding-left:6px;
  -webkit-transition: color 1s;
  transition: color 1s;
  color:white;
}
.asRemoveButton:hover{
  color:red;
}

.btn.btn-card{width:60%; margin:auto;margin-bottom:5px;background-color:rgba(255,255,255,0.6)}
.btn.btn-card-dark{width:60%; margin:auto;margin-bottom:5px;background-color:rgba(255,255,255,0.6);color:black;}

.asButton{border:1px solid #999;padding:3px;display:inline-block;margin-top:5px;}
/* KALENDARZ */
.rbc-month-row{min-height: 12vh;}

/* REACTABLE */
.planeta-table{width:100%;}
.planeta-table.tr, .planeta-table.td{padding:5px;font-size:0.8em;}
.planeta-table.odd{color:brown;}
.reactable-previous-page, .reactable-next-page, .reactable-page-button{
  padding:5px;
  border:1px solid #ccc;
  border-radius:3px;
  margin:2px;
  text-decoration: none;
  color:rgb(53, 53, 53);
  text-decoration: none;

}
.reactable-previous-page:hover, .reactable-next-page:hover, .reactable-page-button:hover{
  text-decoration: none;
  color:black;
}
.reactable-current-page{
  color:rgb(158, 158, 158);
  background-color:rgb(206, 206, 241);
}
.reactable-data tr{cursor:pointer;}
.reactable-data tr:hover{background-color: burlywood;}

.nakedTable td{
  vertical-align:text-top;
  padding:5px;
  text-align: justify;
}
/* INFOS */
.prinfo{
  padding:5px;
  -webkit-box-shadow: 5px 5px 22px -8px rgba(0,0,0,0.75);
-moz-box-shadow: 5px 5px 22px -8px rgba(0,0,0,0.75);
box-shadow: 5px 5px 22px -8px rgba(0,0,0,0.75);
border-radius:5px;
margin-top:10px;
margin-bottom:5px;
padding-bottom:10px;
}
.prinfo.parentInfo{
  max-height:auto;
}
.prinfo.parentInfo::before{
  content:"Informacja o rodzicu";
  color:rgb(121, 121, 121);}

.prinfo.studentInfo::before{
  content:"Informacja o uczniu";
  color:rgb(121, 121, 121);}
.prinfo.courseInfo::before{
  content:"Informacja o kursach";
  color:rgb(121, 121, 121);
}

/* TIME DIFFERENCE */
.timediff{color:#ccc;}
.timediff.small{font-size: 0.6em;}

/* ADMIN FEATURES */

.adminBody{display:none;}
.adminHeader{font-size: 1.1em;cursor: pointer;}
.adminHeader::before{content:">"}
.adminHeader.open{display:block;}


/* WEEK DAYS PANEL */

.weekDays{border:1px solid #eee;padding:3px;margin-top:5px}
.weekDays .day{padding:3px;margin-bottom:5px;}

/* NAVIGATION */
.navContainer{border:1px solid rgb(167, 166, 166);min-height: 60vh;padding:5px;}
.nav-item.pr{padding:5px;border:1px solid #ccc;cursor: pointer;}
.nav-item.pr.active{border:1px solid rgb(167, 166, 166); position:relative}
.nav-item.pr.active::before{content:" ";position:absolute;width:100%; height:5px; background-color:white; bottom:-4px;left:-0px}

/* KANDYDAT */
.kandydat{
  border: 1px solid rgb(136, 136, 136);
  margin-top:5px;
  background-color:#eee;
}
.kandydat.invisibile{display:none}
.kandydat:hover{-webkit-box-shadow: 10px 10px 25px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 25px -8px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 25px -8px rgba(0,0,0,0.75);}
.kandydat .top{
  font-size: 0.7em;

  padding:3px;
}

.kandydat .bottom{
  font-size: 0.8em;
  padding:3px;
}
.kandydat .content{
  padding:3px;
}

.newSchool{
  background-color: darkred; 
  color:white;
  font-size: 0.7em;
  margin:3px;
border-radius:3px;
padding:3px;}

/* SEARCH TABLE */
.selectable{cursor: pointer;}
.selectable.active{background-color: #ccc;}

/* CHAT */
.chatbox{
  position:absolute; 
  top:40px;
  right:50px;
  background-color: white;
  border: 1px solid darkslateblue;
  min-width: 300px;
  z-index:1000;
  display: none;
  -webkit-box-shadow: 10px 10px 28px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 10px 10px 28px 0px rgba(0,0,0,0.75);
box-shadow: 10px 10px 28px 0px rgba(0,0,0,0.75);
}
.chatbox::before{
  content:" ";
  display: block;
  position:absolute;
  background-color: darkslateblue;
  width:10px;
  height:10px;
  top:-5px;
  right:30px;
  z-index:2000;
  transform: rotate(45deg);
}
.chatbox.active{display:block;}
.chatHeader{
  background-color: darkslateblue;
  text-align: center;
  color:white;
  height:20px;
}
.chatMessages{
  min-height: 200px;
  max-height: 70vh;
  background-color: white;
  overflow: auto;


}
.chatmessage{
  margin:5px;
  border-radius:10px;

}

.chatmessage.my{background-color:#ccc;margin-right:20px; border-radius: 10px 0 10px 10px;}
.chatmessage.you{background-color:rgb(0, 44, 102);text-align:right;margin-left:20px;  border-radius: 0 10px 10px 10px;color:white;}
.chatmessage.header{font-size: 0.8em;}
.chatmessage.body{padding:3px;}

.chatNode{
  margin:3px;
  padding:5px;
  background: rgb(172,172,172);
  background: radial-gradient(circle, rgba(172,172,172,1) 0%, rgba(226,226,226,1) 100%);
  border-radius:5px;
  cursor:pointer;
  transition:all 0.9s ease;
}
.chatNode:hover{

  background-color: rgb(70, 196, 255);
  background: radial-gradient(circle, rgb(97, 202, 250) 0%, rgb(202, 234, 248) 100%);
}
.activeChat{text-align: center;padding:4px;color:#777;}
.noChatMessage{text-align: center;padding:4px; padding-top:30%;}
.chatTools{padding:3px;}
.chatTools i{border: 1px solid #ccc;padding:3px;margin-right:3px;}

.runChat{color:#333;cursor: pointer;}
.runChat:hover{color:green;}

.benefity{font-size:2em;cursor: pointer; color:rgb(129, 158, 250);text-align: center;}

/* ANKIETA  */
.desc{font-size:0.7em;font-weight: bold;margin:0px;margin-top:5px;}
.responseAnkieta{padding:5px;}

/* CKEditor */
.ck-content {min-height: 50vh;max-height: 80vh;overflow: scroll;}
.ck-content li{margin-left: 15px;}

/* FILELIST */
.fileList{padding:5px;}

/* NEWS */
.newsContainer{
  background-color: #bbb;
  padding:3px;
}
.newsPanel{
  width:calc(33% - 4px);
  display:inline-block;
  margin:3px;
  background-color: white;
}
.newsPanel img{width:100%}
.newsBody{padding:3px;}
.newsAuthor, .newsDate{
  font-size: 0.7em;
  color:rgb(114, 114, 114);
}

.newsMore{
  text-align: center;
  padding:10px;
  border:1px solid #ddd;
  margin-top:10px;
}
.newsMore:hover{
  background-color: black;
  color:white;
}

/* TIMELINE */

.timeline {
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/* CODESIT TABLE */
table.codesitweek{
  width:100%;
  font-size: 0.8em;
}
table.codesitweek thead td{text-align: center;font-weight: bold;color:#333}
/* table.codesitweek tbody tr:nth-child(even) {background: #fff}
table.codesitweek tbody tr:nth-child(odd) {background: #f5f5f5} */
.codesitweek.gray{background-color: #eee;color:#000;}

.codesit.column div{display:inline-table; margin-bottom:3px;padding:3px}
.codesit.column .first{width:40%}
.codesit.column .second{width:60%; border:1px solid #ddd;}
.category-aktywne div::before
{
  content: "◉ ";
  color:blue;

  border-radius:10px;
}
.category-zamknięte div::before
{
  content: "◉ ";
  color:rgb(0, 0, 0);

  border-radius:10px;
}

.category-nadchodzące div::before
{
  content: "◉ ";
  color:white;
  border-radius:10px;
}


/* LEKCJA */

.mystars
{
  font-size: 0.8em;
  padding:2px;
  background-color:#efefef;
}
.myStar
{
  text-align: center;
  font-size: 0.8em;
}
.myStar.color1{color:#709}
.myStar.color2{color:#854}
.myStar.color3{color:#245}
.myStar.color4{color:#942}
.myStar.color5{color:#283}
.myStar.color6{color:#429}
.myStar.color7{color:#B33}
.myStar.color8{color:#901}

.mystar.active{border:1px solid blue;}

.mystarsarrows.actives{color:green;cursor: pointer;}

.lessonfile{
  padding:3px;
  border:1px solid #ddd;
  margin:3px;
  margin-bottom:15px;
  cursor: pointer;
  display:inline-block;
  border-radius:4px;
}
.lessonfile.active{background-color: rgb(122, 248, 132)}

.codesit.cont div{cursor: pointer;}

.adminItem{min-height:35px;}

.lessoncart{font-size:3.5em;color:rgb(15, 94, 15);}

.shop.document i{
  color:rgb(126, 0, 0);
  font-size:3em;
  display:block;
}
.shop.document div{
  display:block;
}
.shop.document{
display: inline-flex;
width:150px;
text-align: center;
cursor: pointer;
margin-bottom:5px;
}
/* CODESIT SWITCH */
.codesitForm{position: relative}

.codesitInput {
    padding: 7px;
    font-size: 16px;
    border-width: 0px;
    border-color: #CCCCCC;
    background-color: rgba(255,255,255,.5);
    color: #1f1f1f;
    border-style: hidden;
    border-radius: 0px;
    box-shadow: 0px 0px 11px rgba(166,166,166,.75) inset;
    text-shadow: 0px 0px 5px rgba(66,66,66,.75);
    width: 100%;
    width: -webkit-calc(100% - 14px);
    width: -moz-calc(100% - 14px);
    width: calc(100% - 14px);
}
.codesitInput:focus {
    outline:none;
    background-color: rgba(255,255,255,.75);;
}
.codesitInput.icon{
    text-align: right;
}

.smallMessage{
    font-size:.8em;
    color:#ccc;
    position:relative;
}
.currencyIcon{
    position:absolute;
    font-size:1.3em;
    font-weight: bold;
    top:4px;
    left:5px;
}

.paswordStrengthContainer{
    width:100%;
    height:5px;
    background:rgba(255,255,255,.5);
}
.passwordStrength{
    width:100%;
    background-color: white;
    height: 21px;
}

.validatorPlace{
    background-color: rgba(255,255,255,.5);
    padding:3px;
    display:none;
}

.validatorPlace.active{
    display:block;
}

.codesitCheck{
    height:32px;
    width:64px;
    background:rgba(255,255,255,.6);
    -webkit-box-shadow: inset 5px 5px 11px 0px rgba(0,0,0,0.41);
    -moz-box-shadow: inset 5px 5px 11px 0px rgba(0,0,0,0.41);
    box-shadow: inset 5px 5px 11px 0px rgba(0,0,0,0.41);

}
.codesitSwitch{
    height:32px;
    width:32px;
    background-color: red;
    cursor:pointer;
    animation-name: fireOnOff;
    animation-duration: .4s;
}
.codesitSwitch.active{
    height:32px;
    width:32px;
    margin-left:32px;
    background-color: green;
    cursor: pointer;
    animation-name: firedOnOn;
    animation-duration: .4s;
}

@keyframes firedOnOn {
    from {background-color: red; margin-left:0px}
    to {background-color: green; margin-left:32px}
  }

  @keyframes fireOnOff {
    from {background-color: green; margin-left:32px}
    to {background-color: red; margin-left:0px}
    
  }
/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    border:1px solid blue;
    background-color: lightblue;
    color:blue;
    border-radius: 80px;
    margin:2px;
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    font-size:0.7em;
  
    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
  
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  /* Tooltip arrow */
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .dataPresentation{
      background-color: white;
      color:black;
      min-height: 50vh;
  }

.codesitForm.flex{
  display:inline-block;
  padding:5px;
}
.codesitCheck{
  height:32px;
  width:64px;
  background:rgba(255,255,255,.6);
  -webkit-box-shadow: inset 5px 5px 11px 0px rgba(0,0,0,0.41);
  -moz-box-shadow: inset 5px 5px 11px 0px rgba(0,0,0,0.41);
  box-shadow: inset 5px 5px 11px 0px rgba(0,0,0,0.41);

}

.codesitSwitch{
  height:32px;
  width:32px;
  background-color: red;
  cursor:pointer;
  animation-name: fireOnOff;
  animation-duration: .4s;
}
.codesitSwitch.active{
  height:32px;
  width:32px;
  margin-left:32px;
  background-color: green;
  cursor: pointer;
  animation-name: firedOnOn;
  animation-duration: .4s;
}

@keyframes firedOnOn {
  from {background-color: red; margin-left:0px}
  to {background-color: green; margin-left:32px}
}

@keyframes fireOnOff {
  from {background-color: green; margin-left:32px}
  to {background-color: red; margin-left:0px}
  
}

.cTip{
  background-color: rgb(247, 250, 233);
  border: 1px solid rgb(211, 186, 186);
  padding:3px;
}